import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import Layout from "../components/Layout";
import { useProducts } from "../hooks/useProducts";
import { useLocalStorage } from "../hooks/useStorage";
import Underline from "../components/Underline";
import { H1 } from "../components/Typography";
import Button from "../components/Button";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { HTMLContent } from "../components/Content";
import { PriceInfo, QuantitySelect } from "../components/Shop";
import useSiteMetadata from "../components/SiteMetadata";
import { ProductJsonLd } from "../components/JsonLd";
import clsx from "clsx";

const ShopPageTemplate = ({ content }) => {
  const products = useProducts();
  const { siteUrl } = useSiteMetadata();

  return (
    <>
      <GatsbySeo
        title={content.seo.title}
        description={content.seo.description}
        openGraph={{
          url: `${siteUrl}/shop`,
          title: content.seo.title,
          description: content.seo.description,
          images: [{ url: `${siteUrl}${content.seo.image.publicURL}` }],
          site_name: "MycoForest"
        }}
      />
      <section className="section">
        <div className="container">
          <H1 alignCenter>Products</H1>
          <Underline />
          <div className="content">
            {products.map(product => (
              <Product product={product} key={product.slug} />
            ))}
          </div>
        </div>
        <style jsx>{`
          section {
            background: #ffff;
            padding: 32px;
          }
          .container {
            max-width: 1278px;
            margin: 0 auto;
          }

          @media (max-width: 580px) {
            section {
              padding: 32px 20px;
            }
          }
        `}</style>
      </section>
    </>
  );
};

const Product = ({ product }) => {
  const [quantity, setQuantity] = useLocalStorage(product.slug, 1);
  const [showDescription, toggleDescription] = useState(false);
  return (
    <div className="product" key={product.slug}>
      <ProductJsonLd product={product} />
      <div className="images-container">
        <Link to={product.slug}>
          <PreviewCompatibleImage imageInfo={product.images[0]} alt="product" />
        </Link>
      </div>
      <div className="info">
        <div className="md-images-container">
          <PreviewCompatibleImage imageInfo={product.images[0]} alt="product" />
        </div>
        <h3>
          <Link to={product.slug}>{product.name}</Link>
        </h3>
        <HTMLContent className="markdown" content={product.highlights} />
        <HTMLContent
          className={clsx("markdown description", showDescription && "show")}
          content={product.description}
        />
        <button
          className="description-showmore"
          onClick={() => toggleDescription(val => !val)}
        >
          {showDescription ? "Hide" : "Show More"}
        </button>
        <div className="quantity-info">
          <QuantitySelect
            quantity={quantity}
            setQuantity={setQuantity}
            product={product}
          />
          <PriceInfo product={product} quantity={quantity} />
        </div>
        <div className="btns">
          <Button
            isInternal
            width={180}
            href={`${product.slug}`}
            isSolid={false}
          >
            Details
          </Button>
          <Button isInternal width={180} href={`${product.slug}/buy`}>
            Buy Now
          </Button>
        </div>
      </div>
      <style jsx>{`
        .product {
          display: flex;
          margin-bottom: 64px;
          align-items: center;
        }

        .images-container {
          flex: 1;
          order: 1;
        }
        .info {
          flex: 1;
          order: 1;
          margin-left: 32px;
        }

        .product:nth-child(even) .images-container {
          order: 2;
        }

        .product:nth-child(even) .info {
          margin-right: 32px;
        }

        .images-container :global(img) {
          border-radius: 8px;
        }
        h3 {
          font-family: "Open Sans", sans-serif;
          font-weight: 700;
          font-size: 24px;
          margin-top: 0;
        }

        h3 :global(a) {
          color: #000;
        }

        .quantity-info {
          display: flex;
          align-items: center;
        }

        .form-select {
          flex: 1;
          margin-right: 16px;
        }

        .btns {
          margin-top: 16px;
          display: flex;
        }

        .btns :global(.btn),
        .btns :global(.btn-link) {
          margin-right: 16px;
        }

        .md-images-container {
          display: none;
          float: left;
          width: 400px;
          margin-right: 20px;
        }

        @media (max-width: 1180px) {
          .images-container {
            display: none;
          }

          .md-images-container {
            display: block;
          }

          .info :global(.markdown) :global(ul) {
            position: relative;
            left: 2.5rem;
          }
        }

        .info .description-showmore {
          display: none;
        }

        @media (max-width: 1180px) {
          .product {
            flex-direction: column;
          }
          .images-container {
            display: block;
            width: 100%;
          }

          .info {
            margin-left: 0px;
            margin-top: 32px;
          }

          .product:nth-child(even) .images-container {
            order: 1;
          }

          .md-images-container {
            display: none;
          }

          .info :global(.markdown) :global(ul) {
            position: relative;
            left: 0;
          }

          .info :global(.description) {
            height: 115px;
            overflow: hidden;
            position: relative;
          }

          .info :global(.description.show) {
            height: auto;
          }

          .info .description-showmore {
            width: 100%;
            background: #fff;
            box-shadow: 0 -15px 15px 5px #fff;
            outline: none;
            border: none;
            display: block;
            text-align: left;
            appearance: none;
            box-shadow: 0 -15px 15px 5px #fff;
            z-index: 10;
            margin-bottom: 16px;
            position: relative;
            height: 24px;
            display: flex;
            align-items: flex-start;
            top: -12px;
            color: #ff8100;
            font-size: 14px;
          }

          .info :global(.description) :global(p) {
            margin-top: 0;
          }
        }

        @media (max-width: 580px) {
          h3 {
            font-size: 20px;
          }

          .info {
            margin-top: 24px;
          }
        }

        @media (max-width: 525px) {
          .quantity-info {
            align-items: flex-start;
            flex-direction: column-reverse;
          }

          .quantity-info :global(.form-select) {
            margin-right: 0;
            margin-top: 16px;
            width: 100%;
          }

          .btns :global(.btn),
          .btns :global(.btn-link) {
            margin-right: 0;
            width: 100%;
            font-size: 16px;
            align-items: center;
          }

          .btns :global(.btn):first-child,
          .btns :global(.btn-link):first-child {
            margin-right: 16px;
          }
        }

        @media (max-width: 390px) {
          .btns :global(.btn),
          .btns :global(.btn-link) {
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
};

const ShopPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout whiteHeader isShopPage>
      <ShopPageTemplate content={frontmatter} />
    </Layout>
  );
};

export default ShopPage;

export const pageQuery = graphql`
  query ShopPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "shop-page" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            publicURL
          }
        }
      }
    }
  }
`;
